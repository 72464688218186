import {Box, CircularProgress, debounce, InputAdornment, MenuItem, Select} from '@mui/material';
import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {errorBigIcon, iconNotifyWarningInfo, searchIcon, toastSuccess} from '../../../../assets/icons/icons';
import {Button, Typography} from '../../../../components/StyledComponents';
import {
  EPaymentRequiredType,
  EPlatformsNames,
  ESubscriptionPlans,
  ETrialStatus,
  SubscriptionMockData
} from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup, PlatformProps} from '../../../../models/inner-models';
import Platform from '../../../../models/platforms-data';
import {
  backupNotionItem,
  deleteNotionItem,
  fetchNotionUser,
  fetchUserWorkspacesNotion, getAllSubscriptions,
  getNotionLoginUrl
} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  getIsTestUser,
  getNotionCanUserBackup,
  getNotionTotal,
  getNotionUsersSelector, getNotionWorkspaceSelector,
  isNotionUsersLoadingSelector, isNotionWorkspaceLoadingSelector, isSubUser,
  loadingUser,
  notionSubscription,
  notionTrialHoursLeft,
  notionTrialPlanQuantity,
  notionTrialQuantityLeft,
  notionTrialStatus, roleByApp
} from '../../../../store/selectors';
import {SearchInput} from '../../../../styles/components/MainInputElements';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import fetchItemsErrorHandler from "../../../../utils/fetchItemsErrorHandler";
import NoBackupsBlock from "../../../../components/Blocks/NoItemsBlock/NoItemsBlock";
import PlatformItemsList from "../../../../components/PlatformComponents/PlatformItemsList/PlatformItemsList";
import PaymentRequiredPoppup from "../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import Modal from "../../../../components/Dialogs/Modal/Modal";
import NotionUserAddEdit from "../NotionUserAddEditView/NotionUserAddEdit";
import DatabaseIcoInvert from "../../../../assets/icons/CustomSvg/DatabaseIcoInvert";
import {BadgesBackup, BadgesNotionUpd, BadgesSubscription, BadgesTrial} from "../../../../components/Badges/Badges";
import NotionTutorial from "./NotionTutorial/NotionTutorial";
import {Info, Plus} from "react-feather";
import CustomTimerTooltip from "../../../../styles/components/CustomTimerTooltip";
import {IPlatformUser} from "../../../../models/models";
import DeleteItemDialog from "../../../../components/Dialogs/DeleteItemDialog/DeleteItemDialog";
import {CustomTooltip} from '../../../../styles/components/CustomTooltip';
import {updateSubscriptionPlanModal} from "../../../../utils/functions/updateSubscriptionPlanModal";
import ExternalStorage from "../../../UserPages/UserSettings/ExternalStorage/ExternalStorage";
import NoSubscriptionBlock from "../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock";
import {iframeNewTabMsg, iniFrame} from "../../../../serviceWorker";

const NotionPagesList: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const userLoading = useAppSelector(loadingUser);
  const workspaces = useAppSelector(getNotionWorkspaceSelector);
  const canUserBackup = useAppSelector(getNotionCanUserBackup);
  const isTestUser = useAppSelector(getIsTestUser);
  const isLoadingWorkspaces = useAppSelector(isNotionWorkspaceLoadingSelector);
  const isLoadingTrelloUsers = useAppSelector(isNotionUsersLoadingSelector);
  const trialState = useAppSelector(notionTrialStatus);
  const trialDays = useAppSelector(notionTrialHoursLeft);
  const trialQuantity = useAppSelector(notionTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(notionTrialPlanQuantity);
  const subInfo = useAppSelector(notionSubscription);
  const total = useAppSelector(getNotionTotal);
  const users = useAppSelector(getNotionUsersSelector);
  const isUserSub = useAppSelector(isSubUser);
  const roleApp = useAppSelector(roleByApp);
  const isAdminRole = isUserSub && roleApp?.notion === 'admin' || !isUserSub
  // disable btn sub not admin - isUserSub && roleApp?.notion === 'user'
  const dispatch = useAppDispatch();
  const platformName = EPlatformsNames.NOTION;
  const platform = Platform[platformName];
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [platformInfo, setPlatformInfo] = useState<PlatformProps>(
    {
      platformName: platformName,
      subPlan: SubscriptionMockData[ESubscriptionPlans.NO_SUB],
      items: workspaces || [],
      users: users || [],
      filteredItems: workspaces || [],
    },
  );
  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName,
  });
  const [paymentState, setPaymentState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);
  const [buyShowed, setBuyShowed] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showSettings2, setShowSettings2] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectList, setSelectList] = useState<Array<string>>([]);
  const [searchParams] = useSearchParams();
  const [progressItem, setProgressItem] = useState<string>('');
  const [progressEnd, setProgressEnd] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [intervalFunc, setIntervalFunc] = useState<any>();
  const [isOpenTutorial, setIsOpenTutorial] = useState<boolean>(false);
  const [userCanBackup, setUserCanBackup] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>(localStorage['notionUser'] || '1');
  const [deleteDate, setDeleteData] = useState<{ id?: string, name?: string, type?: string }>({});
  const [deleteBulkDate, setDeleteBulkData] = useState<Array<string>>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clearState = (notPage?: boolean) => {
    setDeleteData({})
    setDeleteBulkData([])
    setSelectList([])
    setSelectAll(false)
    if (!notPage) {
      setPage(0)
    }
  }

  const setUserWorkspace = (userList: IPlatformUser[] | null) => {
    if(!!userList?.length && userList[0].ownerId) {
       if (localStorage['notionUser'] && !!userList?.filter(i=> i.ownerId === String(localStorage['notionUser']))?.length) {
        setUserId(String(localStorage['notionUser']))
        return localStorage['notionUser'] as string
      } else {
        localStorage['notionUser'] = userList[0].ownerId
        setUserId(String(userList[0].ownerId))
        return userList[0].ownerId as string
      }
    }
  }

  const swalTutorialModal = async () => {
    return await Swal.fire({
      title: 'Notion: Backups Confirmation',
      html: '<div><p><div>1. Inside Notion open "Access selected pages"</div><div>2. Manually select any new pages and teamspaces</div></p> <p><b>Warning!</b> This is only if you create a new page or teamspace</p></div>',
      text: 'To keep the backup up to date of your Notion workspace, please add permissions for our app to make a backup of the newly added pages',
      confirmButtonText: translate('notifications.choices.continue'),
      denyButtonText: translate('notifications.choices.tutorial'),
      cancelButtonText: translate('notifications.choices.cancel'),
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,
      allowOutsideClick: true,
      imageUrl: iconNotifyWarningInfo,
    });
  }
  const addWorkspace = async ()=> {
    const confirmationChoice = await swalTutorialModal()

    if (!confirmationChoice.isConfirmed && !confirmationChoice.isDenied) {
      return;
    }

    if (confirmationChoice.isDenied) {
      setIsOpenTutorial(true)
      return;
    }
    if(!platformInfo?.subPlan?.quantity){
      Swal.fire({
        title: translate('notifications.subscription.buy_plan_title'),
        text: platformInfo.subPlan && platformInfo.subPlan.name === 'Trial' ?
          translate('notifications.subscription.buy_plan') :
          translate('notifications.subscription.upgrade_plan'),
        imageUrl: iconNotifyWarningInfo,
        cancelButtonText: translate('notifications.choices.nevermind'),
        confirmButtonText: platformInfo.subPlan && platformInfo.subPlan.name === 'Trial' ?
          translate('notifications.choices.buy_subscription') :
          translate('notifications.choices.upgradeSub'),
        showCancelButton: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName || '')
            navigate('/checkout');
          }
        })
    } else {
      if (iniFrame()) {
        iframeNewTabMsg()
      } else {
        const response = await dispatch( getNotionLoginUrl()).unwrap()
        window.open(response.url, '_self');
      }
    }

  }

  useEffect(() => {setUserCanBackup(canUserBackup)},[canUserBackup])

  const restartBadge = () => {
    if (intervalFunc) {
      clearInterval(intervalFunc)
      setIntervalFunc(null)
    }
    setProgressEnd(false)
    setProgressItem('')
  }

  useEffect(() => {
    if (localStorage['showUpdNotionPopup']) {
      setIsOpenTutorial(true)
    }
    const isExt = searchParams.get('isCloudStorageConnected');
    const errorMessage = searchParams.get('error_message');

    if (!errorMessage && isExt) {
      navigate(window.location.pathname, { replace: true })
      setShowSettings2(true)
    }
  }, []);

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setPlatformInfo((prev) => ({
        ...prev,
        subPlan,
      }));
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingWorkspaces && !isLoadingTrelloUsers) {
      setPlatformInfo((prev) => ({
        ...prev,
        items: workspaces || [],
        users: users || [],
        filteredItems: workspaces || [],
      }));

      const filt = workspaces?.filter(itemB => !!itemB.actionProcess?.length)
      if (filt && filt.length > 1 && !progressItem.length) {
        const isRest = filt.filter(itemB => itemB.actionProcess === 'restoring_process')
        filt.length === isRest?.length ?
          setProgressItem(translate(`notifications.all.actionBadge.many.restore`, {app: 'Notion'})) :
          !!isRest?.length ?
            setProgressItem(translate(`notifications.all.actionBadge.multi`)) :
            setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'Notion'}))
      } else if (filt?.length === 1 && !progressItem.length) {
        setProgressItem(translate(`notifications.all.actionBadge.one.${filt[0].actionProcess}`, {app: 'Notion', item: filt[0].workspace?.split('::')[0]}))
      } else if (!filt?.length && !!progressItem?.length) {
        if (intervalFunc) {
          clearInterval(intervalFunc)
        }
        setProgressEnd(true)
      }
    }
  }, [workspaces, users, isLoadingWorkspaces, isLoadingTrelloUsers]);

  const fetchData = async (newPage?: number,name?:string) => {
    let usersList: IPlatformUser[]|null= []
    try {
      setIsLoading(true)
      usersList = (await dispatch(fetchNotionUser()).unwrap().catch(err => console.log(err)));
      setUserWorkspace(usersList)
      dispatch(fetchUserWorkspacesNotion({page: newPage || 0, perPage: 10,name, ownerId: String(setUserWorkspace(!!usersList ? usersList : users)) })).unwrap()
        .catch((err) => {
          console.log(err)
          const error = err as IError;
          fetchItemsErrorHandler(error)
        }).finally( () => {
        setIsLoading(false)
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) {
      navigate('/app-connector')
    }
    fetchData();
  }, []);

  const debouncedSearch = useCallback(
    debounce((event) => {
      setPage(0)
      fetchData(0, event.toLowerCase())
    }, 1000),
    [],
  );

  const onSearch = useCallback((event) => {
    setPage(0)
    const val = event.target.value || ''
    setSearchTerm(val);
    debouncedSearch(val)
  }, []);

  useEffect(() => {
    if (isUserSub && !roleApp?.[platformName]) {
      navigate('/', {replace: true})
    }
  }, [isUserSub, roleApp]);

  useEffect(() => {
    if ((platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours) ||
      (platformInfo.subPlan.type === ESubscriptionPlans.SUBSCRIPTION && !platformInfo.subPlan.isPaid)) {
      setPaymentState(
        platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours ?
          EPaymentRequiredType.TRIAL : platformInfo.subPlan.isPaid ?
            EPaymentRequiredType.NON : EPaymentRequiredType.SUB,
      );
    } else {
      setPaymentState(EPaymentRequiredType.NON)
    }

    if (!isUserSub && platformInfo.subPlan.isPaid !== undefined && !platformInfo.subPlan.isPaid && !buyShowed) {
      setBuyShowed(true)
      Swal.fire({
        title: translate('notifications.subscription.subscribe_buy'),
        text: translate('notifications.subscription.subscribe_buy_text'),
        cancelButtonText: translate('notifications.choices.close'),
        confirmButtonText: translate('notifications.choices.buy_subscription_now'),
        showCancelButton: true,
        imageUrl: errorBigIcon,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName)
            navigate('/checkout');
          }
        })
    }
  }, [platformInfo]);

  useEffect(() => {
    if (!isLoadingWorkspaces && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && !buyShowed) {
      setBuyShowed(true)
      Swal.fire({
        title: !!subInfo ? translate('notifications.subscription.subscribe_buy') : translate('notifications.subscription.subscribe_buy_trial'),
        text: !!subInfo ? translate('notifications.subscription.subscribe_buy_text') : translate('notifications.subscription.subscribe_buy_text_trial'),
        cancelButtonText: translate('notifications.choices.close'),
        confirmButtonText: translate('notifications.choices.buy_subscription_now'),
        showCancelButton: true,
        imageUrl: errorBigIcon,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName)
            navigate('/checkout');
          }
        })
    }
  }, [isLoadingWorkspaces, subInfo]);

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({...paymentPoppup, isOpen: false});
  }, []);

  const handleDeleteBulkWorkspaces= async (arr) => {
    setDeleteBulkData(arr);
  };

  const onConfirmDeleteWorkspace = async (id?, name?, isDelBulk?) => {
    try {
      setIsLoading(true)
        await dispatch(deleteNotionItem({ids: isDelBulk ? id : [id]})).unwrap()
      await fetchData()
      dispatch(getAllSubscriptions()).unwrap()

      restartBadge()
      clearState()

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: isDelBulk ?
          translate('notifications.notion_workspaces.successfully_deleted_many') :
            translate('notifications.notion_workspaces.successfully_deleted') ,
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      setIsLoading(false)

    } catch (err) {
      const error = err as IError;
      // setIsLoading(false)

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const backupAllNow = async () => {
    try {
      if (!isUserSub) {
        const confirmationChoice = await swalTutorialModal()

        if (!confirmationChoice.isConfirmed && !confirmationChoice.isDenied) {
          return;
        }

        if (confirmationChoice.isDenied) {
          setIsOpenTutorial(true)
          return;
        }
      }

      if (!isTestUser) {
        setUserCanBackup(false)
      }

      await dispatch(backupNotionItem(userId)).unwrap();
      // dispatch(fetchUserWorkspaceNotion()).unwrap()
      restartBadge()
      setProgressItem(translate(`notifications.all.actionBadge.one.backing_up_process`, {app: 'Notion', item: workspaces?.[0]?.workspace?.split('::')[0]}))

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: platformInfo?.filteredItems&& platformInfo.filteredItems?.length < 2 ?translate('notifications.github_repos.single_start_backup'):translate('notifications.github_repos.multi_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  const textDisplay =(text)=>{
   return  text.length > 20 ? `${text.substring(0, 20)}...` : text
  }

  return (
    <div id="platform-content" className='list-view'>
      <BadgesNotionUpd key='backupPage' platformName='notion' onClose={()=>setIsOpenTutorial(true)}/>

      {!!progressItem?.length && (
        <BadgesBackup progressItem={progressItem} platformName={platformName} isComplete={progressEnd} onClose={() => {
          restartBadge()
        }}/>
      )}
      {trialState === 'active' && trialDays && (
        <BadgesTrial trialDays={trialDays} platformName={platformName}/>
      )}
      {!isLoadingWorkspaces && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && (
        <BadgesTrial platformName={platformName} color={!!workspaces?.filter(i=>i.lastBackup !== '-').length ? 'error' : 'error_2'} isSub={!!subInfo}/>
      )}
      {!isLoadingWorkspaces && ((trialState === 'active' && trialDays && !trialQuantity) || (subInfo && subInfo.status === 'active' && !subInfo.quantity)) && (
        <BadgesTrial platformName={platformName} color='warning' isSub={subInfo ? subInfo.status === 'active' : false}/>
      )}
      {subInfo && subInfo?.isCancel && subInfo?.finishDateInMillisecond && ((subInfo?.finishDateInMillisecond- Date.now())/(24*60*60*1000))<1 &&<BadgesSubscription platformName={platformName}/>}

      <div className="header-block">
        <Typography variant="h3" gutterBottom display="inline">
          {translate('forms.github_add_edit_repo.your_items', {platform: platform?.title, itemName: ''})}
        </Typography>
        {!isUserSub && (
          <span
            className='notion-help-btn'
            onClick={() => {
              setIsOpenTutorial(true)
            }}
            title={translate('notifications.notion_tutorial.tooltip')}
          >
            <Info className="sliders-icon"/>
          </span>
        )}

        <div className="search-block">
          <div className="action-button">
            {/*{!!workspaces?.length && (trialState === 'active' || subInfo || !!platformInfo?.users?.length) && (*/}
            {/*  <Button variant="outlined" color="primary"*/}
            {/*          onClick={() => setShowSettings(true)}>*/}
            {/*    {translate('forms.github_add_edit_repo.open_setting')}*/}
            {/*  </Button>*/}
            {/*)}*/}
            {!!workspaces?.length && (trialState === 'active' || subInfo || !!platformInfo?.users?.length) && (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}
              >
                <Button variant="outlined" color="primary" mr={2}
                        disabled={platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial' || paymentState !== EPaymentRequiredType.NON}
                        onClick={() => setShowSettings2(true)}>
                  External storage
                </Button>
              </CustomTooltip>
            )}
            {!!workspaces?.length && ((userCanBackup || isTestUser) ? (
              <CustomTooltip title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}>
                <Button
                  mr={2} variant="outlined"
                  color="primary" onClick={()=>(platformInfo.subPlan?.name === 'Pro' || platformInfo.subPlan?.name === 'Trial') ? backupAllNow() : updateSubscriptionPlanModal({translate,platformName,navigate}) }
                  disabled={(platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                    (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) ||
                    paymentState !== EPaymentRequiredType.NON || !isAdminRole}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTooltip>
              ) : (
              <CustomTimerTooltip setUserCanBackup={()=>setUserCanBackup(true)}>
                <Button
                  mr={2} variant="outlined"
                  color="primary"
                  onClick={()=>(platformInfo.subPlan?.name === 'Pro' || platformInfo.subPlan?.name === 'Trial') ? backupAllNow() : updateSubscriptionPlanModal({translate,platformName,navigate})}
                  disabled={true}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTimerTooltip>
            ))}
            {(!!platformInfo?.users?.length) && (//trialState === 'active' || subInfo ||
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isUserSub}
                  onClick={()=>(addWorkspace())}
                >
                  <Plus className="feather-icon-in-button"/>
                  {translate('forms.notion_add_edit_user.add_workspace')}
                </Button>
            )}
          </div>
        </div>

      </div>

      <div className="header-block header-block-sub">
        <div className="user-name">
          {platformInfo && !!platformInfo.users?.length && (
            <Select
              value={userId}
              className='github-org-select'
              MenuProps={{id: 'sub-notion-select'}}
            >
              {platformInfo.users?.map(user => (<MenuItem
                      key={user.ownerId}
                      value={user.ownerId}
                      onClick={() => {
                        setIsLoading(true)
                        restartBadge()
                        clearState()
                        dispatch(fetchUserWorkspacesNotion({
                          page: page,
                          perPage: 10,
                          ownerId: String(user.ownerId)
                        })).unwrap().finally(() => setIsLoading(false))
                        localStorage['notionUser'] = user.ownerId
                        setUserId(String(user.ownerId))
                      }}
                    >
                    {`@${textDisplay(user.username)} (${textDisplay(user.email)})`}
                    </MenuItem>
                  )
              )}
            </Select>
          )}
        </div>
        <div className="search-block">
          {(!!workspaces?.length || !!searchTerm) && (
            <SearchInput
              className="search-field"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={onSearch}
              endAdornment={
                <InputAdornment position="end" className="search-icon">
                  <img src={searchIcon} loading="lazy"/>
                </InputAdornment>
              }
            />
          )}
        </div>
      </div>

      <Box sx={{width: '100%'}}>
        {isLoadingTrelloUsers || isLoadingWorkspaces || userLoading || isLoading  ?
          <div className="spinner-wrapper">
            <CircularProgress/>
          </div>
          :
          <div className="data-block">
            {(platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
            (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED && !workspaces?.length) ?
              <NoSubscriptionBlock platformName={platformName} isOwner={!isUserSub}/>
              : !users?.length || !users[0]?.username ?
              <NoBackupsBlock
                isAdmin={!isUserSub}
                action={() => navigate('/app-connector')}
                platformItem={translate('views.bulk_restore.itemName.trello.one')}
                actionText={translate('forms.github_add_edit_repo.configure_account')}
              />
              : !platformInfo.items?.length ?
                <NoBackupsBlock
                  isSearch={!!searchTerm}
                  isAdmin={!isUserSub}
                  action={addWorkspace}
                  platformItem={translate('views.bulk_restore.itemName.trello.one')}
                  actionText={translate('forms.notion_add_edit_user.add_workspace')}
                />
                :
                <>
                  <div className="repo-wrapper">
                    <div className="repos-list">
                      <PlatformItemsList
                        items={platformInfo.filteredItems || []}
                        updItems={() => dispatch(fetchUserWorkspacesNotion({page: page, perPage: 10,ownerId:userId})).unwrap()}
                        platformName={platformName}
                        // onBackup={(id) => dispatch(backupNotionItem(id)).unwrap()}
                        setSelectAll={(isSelect) => {
                          if (!isSelect) {
                            setSelectList([])
                            setSelectAll(isSelect)
                          } else {
                            const listToSelect = platformInfo.filteredItems?.filter(item => !item.actionProcess && item.status !== "initialized").map(item => item.id) || []
                            setSelectList(listToSelect)
                            if (listToSelect.length === platformInfo.filteredItems?.length) {
                              setSelectAll(isSelect)
                            }
                          }
                        }}
                        selectAll={selectAll}
                        setSelectList={(name) => (
                          setSelectList(prevState => {
                            const indexToRemove = prevState.indexOf(name);
                            if (indexToRemove !== -1) {
                              prevState.splice(indexToRemove, 1);
                            } else {
                              prevState.push(name)
                            }
                            if (!!prevState?.length && !!platformInfo.filteredItems?.length &&
                              ((prevState.length === platformInfo.filteredItems.length && !selectAll) ||
                                (prevState.length !== platformInfo.filteredItems.length && selectAll))) {
                              setSelectAll(prevState.length === platformInfo.filteredItems.length)
                            }
                            if (!prevState.length) {
                              setSelectAll(false)
                            }
                            return [...prevState]
                          })
                        )}
                        selectList={selectList}
                        subPlan={platformInfo.subPlan}
                        users={platformInfo.users}
                        trialState={trialState}
                        total={total}
                        page={page}
                        setPage={(p)=>{
                          setPage(p)
                          clearState(true)
                          fetchData(p,searchTerm)
                        }}
                        isLoading={false}
                        onBulkDelete={handleDeleteBulkWorkspaces}
                        isAdmin={isAdminRole}
                      />
                    </div>
                  </div>
                </>
            }
            <PaymentRequiredPoppup
              isOpen={paymentPoppup.isOpen}
              closeNotification={closeTrialPoppup}
              type={paymentState}
              platformName={paymentPoppup.platformName}
            />
            <Modal
              isOpen={showSettings}
              closeNotification={() => setShowSettings(false)}
              title='Edit settings'
              child={<NotionUserAddEdit
                user={users ? users[0] : {}}
                subPlan={platformInfo.subPlan}
              />}
            />
            <Modal
              isOpen={showSettings2}
              className='ext-storage-modal'
              closeNotification={() => setShowSettings2(false)}
              child={<ExternalStorage
                platformModal='notion'
                closeModal={() => setShowSettings2(false)}
              />}
            />
          </div>
        }
      </Box>
      <NotionTutorial isOpen={isOpenTutorial} closeNotification={() => setIsOpenTutorial(false)}/>

      <DeleteItemDialog
        isOpen={!!deleteDate?.name?.length || !!deleteBulkDate.length}
        closeNotification={() => {clearState(true)}}
        platformName={platformName}
        isBulk={!!deleteBulkDate.length}
        bulkData={deleteBulkDate}
        singleData={deleteDate}
        paymentState={paymentState}
        items={platformInfo.filteredItems || []}
        name={deleteDate?.name || ''}
        updItems={() =>  dispatch(fetchUserWorkspacesNotion({page: page, perPage: 10, ownerId:userId})).unwrap()}
        onConfirm={() => {
          console.log(deleteBulkDate)
          deleteBulkDate.length > 1 ?
            onConfirmDeleteWorkspace(deleteBulkDate, true,true)  :
            onConfirmDeleteWorkspace(deleteBulkDate[0])
        }}
      />
    </div>
  );
};

export default withRedirectAfterLogout()(NotionPagesList);
